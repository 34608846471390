/**
 * Type: ページ
 * What: 完全自己責任！？ふれあい動物園ノースサファリサッポロ（2021年）
 */
import { useEffect } from 'react';

// Data Props Template
const Template = () => {
  // 初回DOM
  useEffect(() => {
    window.location.href = 'https://e-zofukuoka.com/special/fureaizoo2022/';
  });
  return true;
};

export default Template;
